<script>
    import { Bar, mixins } from 'vue-chartjs'
    import {precise} from "../../../../utility/helper-functions";
    const { reactiveProp } = mixins

    export default {
        extends: Bar,
        mixins: [reactiveProp],
        props: {
            yScaleLabel: String,
            maintainAspectRatio: Boolean,
            title: String,
            chartData: Object,
            handler: {
                type: Function,
                default: () => {}
            }
        },
        data: function () {
            return {
                options : {
                    onClick: this.handler,
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            gridLines: {
                                display:false
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 14,
                                fontColor: "white",
                            },
                        }],
                        yAxes: [{
                            gridLines: {
                                zeroLineColor: 'orange',
                                drawBorder: false
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 14,
                                callback: function(value) {if (value % 1 === 0) {return value;}}
                            },
                            display: true
                        }]
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        intersect: true
                    },
                    events: ['click'],
                    plugins: {
                        datalabels: {
                            color: '#f0f8fa',
                            clamp: true,
                            anchor: 'end',
                            offset: 0,
                            align: 'bottom',
                            events: ['click'],
                            formatter: function(value) {
                                let type = typeof value;
                                if(type === "object") {
                                    return precise(value) + '%';
                                } else {
                                    return precise(value) + '%';
                                }
                            }
                        }
                    }
                }
            }
        },

        mounted () {
            this.renderChart(this.chartData, this.options)
        }
    }
</script>

<style scoped>

</style>
